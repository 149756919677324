import { Component, OnInit } from '@angular/core';
import { HelperService } from 'src/app/service/helper.service';

@Component({
  selector: 'app-produtividade-colaboracao',
  templateUrl: './produtividade-colaboracao.component.html',
  styleUrls: ['../solucoes.component.scss']
})
export class ProdutividadeSegurancaComponent implements OnInit {

  constructor(
    public helper: HelperService
  ) { }

  public solucoes: any [] = [
    {
      id: 1,
      img: './assets/images/solucoes/img-produtividade-workspace.png',
      titulo: 'Google Workspace',
      descricao: 'As melhores ferramentas de colaboração e produtividade para sua empresa',
      icone: './assets/icons/solucoes/i-seta-solucoes-roxo.svg',
      link: '/solucoes/produtividade-colaboracao/google-workspace'
    },
    {
      id: 2,
      img: './assets/images/solucoes/img-produtividade-cloud-migrator.png',
      titulo: 'CloudMigrator',
      descricao: 'Migre facilmente os dados de sua empresa para o Google Workspace',
      icone: './assets/icons/solucoes/i-seta-solucoes-roxo.svg',
      link: '/solucoes/produtividade-colaboracao/cloud-migrator'
    },
    {
      id: 3,
      img: './assets/images/solucoes/img-produtividade-gestor-assinatura.png',
      titulo: 'Gower - Gestor de Assinaturas',
      descricao: 'Automatize o gerenciamento de assinaturas de emails da sua empresa',
      icone: './assets/icons/solucoes/i-seta-solucoes-roxo.svg',
      link: '/solucoes/produtividade-colaboracao/gestor-assinatura'
    },
    {
      id: 4,
      img: './assets/images/solucoes/img-produtividade-qi-academy.png',
      titulo: 'Qi Academy',
      descricao: 'Plataforma de ensino para colaboradores aprenderem como utilizar os aplicativos do Google Workspace.',
      icone: './assets/icons/solucoes/i-seta-solucoes-roxo.svg',
      link: '/solucoes/produtividade-colaboracao/qi-academy'
    },
    {
      id: 5,
      img: './assets/images/solucoes/img-produtividade-single-sign.png',
      titulo: 'SSO - Single Sign On',
      descricao: 'Define dias e horários que colaboradores podem ter acesso ao Google Workspace',
      icone: './assets/icons/solucoes/i-seta-solucoes-roxo.svg',
      link: '/solucoes/produtividade-colaboracao/sso'
    }
  ];

  public clientes: any[] = [
    {
      id: 1,
      solucoes: 'infra',
      logo: './assets/logos/institucional/squadra.svg',
      alt: 'squadra',
      tipo: 'Case Study',
      descricao: 'A Squadra Tecnologia migrou seus servidores para o Google Cloud em 30 dias.',
      tempo: '8 min leitura',
      icone: './assets/icons/institucional/i-proximo-roxo.svg',
      link: ''
    },
    {
      id: 2,
      solucoes: 'infra',
      logo: './assets/logos/institucional/metropoles.svg',
      alt: 'metropoles',
      tipo: 'Video',
      descricao: 'Migração cloud: como o Metrópoles migrou com segurança para o Google Cloud',
      tempo: '2:52 min',
      icone: './assets/icons/institucional/i-play-roxo.svg',
      link: ''
    },
    {
      id: 3,
      solucoes: 'dados',
      logo: './assets/logos/institucional/squadra.svg',
      alt: 'squadra',
      tipo: 'Case Study',
      descricao: 'A Squadra Tecnologia migrou seus servidores para o Google Cloud em 30 dias.',
      tempo: '8 min leitura',
      icone: './assets/icons/institucional/i-proximo-roxo.svg',
      link: ''
    }
  ];

  ngOnInit() {

    this.helper.jInfra = false;
    this.helper.jProdutividade = true;
    this.helper.jInteligencia = false;
    this.helper.ajudaCinza = false;
    this.helper.ajudaAzul = true;

  }
  public smooth(id) {
    document.getElementById(id).scrollIntoView({behavior:"smooth"});
  }

}
