import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { SlickCarouselModule } from 'ngx-slick-carousel';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HelperService } from './service/helper.service';
import { NavComponent } from './layout/nav/nav.component';
import { FooterComponent } from './layout/footer/footer.component';
import { HomeComponent } from './pages/home/home.component';
import { ProgramaInovacaoComponent } from './pages/programa-inovacao/programa-inovacao.component';
import { JornadaProdutividadeComponent } from './pages/jornada-produtividade/jornada-produtividade.component';
import { JornadaProdEtapasComponent } from './pages/jornada-prod-etapas/jornada-prod-etapas.component';
import { CampanhaLgpdComponent } from './pages/campanha-lgpd/campanha-lgpd.component';
import { CampanhaLgpdNComponent } from './pages/campanha-lgpd-n/campanha-lgpd-n.component';
import { JornadaModernizacaoComponent } from './pages/jornada-modernizacao/jornada-modernizacao.component';
import { JornadaInteligenteComponent } from './pages/jornada-inteligente/jornada-inteligente.component';
import { JornadaModerEtapasComponent } from './pages/jornada-moder-etapas/jornada-moder-etapas.component';
import { JornadaIntelEtapasComponent } from './pages/jornada-intel-etapas/jornada-intel-etapas.component';
import { CampanhaModernizacaoComponent } from './pages/campanha-modernizacao/campanha-modernizacao.component';
import { ClientesComponent } from './pages/clientes/clientes.component';
import { VerticaisComponent } from './pages/verticais/verticais.component';
import { TrabalheConoscoComponent } from './pages/trabalhe-conosco/trabalhe-conosco.component';
import { IndustriaComponent } from './pages/verticais/industria/industria.component';
import { LogisticaComponent } from './pages/verticais/logistica/logistica.component';
import { SaudeComponent } from './pages/verticais/saude/saude.component';
import { TecnologiaComponent } from './pages/verticais/tecnologia/tecnologia.component';
import { VarejoComponent } from './pages/verticais/varejo/varejo.component';
import { AjudaComponent } from './pages/ajuda/ajuda.component';
import { SobreComponent } from './pages/sobre/sobre.component';
import { CasesComponent } from './pages/cases/cases.component';
import { ServicosComponent } from './pages/servicos/servicos.component';
import { SolucoesComponent } from './pages/solucoes/solucoes.component';
import { ModernizacaoInfraComponent } from './pages/solucoes/cloud-services/cloud-services.component';
import { JornadaSolucoesComponent } from './pages/jornada-solucoes/jornada-solucoes.component';
import { ProdutividadeSegurancaComponent } from './pages/solucoes/produtividade-colaboracao/produtividade-colaboracao.component';
import { InteligenciaDadosComponent } from './pages/solucoes/inteligencia-dados/inteligencia-dados.component';
import { SapGooglecloudComponent } from './pages/sap-googlecloud/sap-googlecloud.component';
import { TrabalhoDaasComponent } from './pages/trabalho-daas/trabalho-daas.component';
import { MigracaoNuvemComponent } from './pages/migracao-nuvem/migracao-nuvem.component';
import { ContatoComponent } from './pages/contato/contato.component';
import { GoogleWorkspaceComponent } from './pages/google-workspace/google-workspace.component';
import { CloudMigratorComponent } from './pages/cloud-migrator/cloud-migrator.component';
import { GestorAssinaturaComponent } from './pages/gestor-assinatura/gestor-assinatura.component';
import { QiAcademyComponent } from './pages/qi-academy/qi-academy.component';
import { ServicosProdutividadeComponent } from './pages/servicos-produtividade/servicos-produtividade.component';
import { ServicosModernizacaoComponent } from './pages/servicos-modernizacao/servicos-modernizacao.component';
import { ServicosInteligenciaComponent } from './pages/servicos-inteligencia/servicos-inteligencia.component';
import { SsoComponent } from './pages/sso/sso.component';
import { BancoDadosGooglecloudComponent } from './pages/banco-dados-googlecloud/banco-dados-googlecloud.component';
import { GoogleDataStudioComponent } from './pages/google-data-studio/google-data-studio.component';
import { EbooksComponent } from './pages/ebooks/ebooks.component';
import { WebinarsComponent } from './pages/webinars/webinars.component';
import { WebinarsCloudComponent } from './pages/webinars-cloud/webinars-cloud.component';
import { CaseEspecificoComponent } from './pages/case-especifico/case-especifico.component';
import { WebinarsWorkspaceComponent } from './pages/webinars-workspace/webinars-workspace.component';
import { WebinarsGsuiteComponent } from './pages/webinars-gsuite/webinars-gsuite.component';
import { NotFoundComponent } from './pages/not-found/not-found.component';
import { SeloComponent } from './pages/solucoes/cloud-services/components/selo/selo.component';
import { SaudePillarPageComponent } from './pages/verticais/saude/saude-pillar-page/saude-pillar-page.component';
import { MigrationFactoryComponent } from './pages/migration-factory/migration-factory.component';
import { DevopsCloudComponent } from './pages/devops-cloud/devops-cloud.component';
import { CertificacoesComponent } from './pages/certificacoes/certificacoes.component';
import { TecnologiaPillarPageComponent } from './pages/verticais/tecnologia/tecnologia-pillar-page/tecnologia-pillar-page.component';
import { VarejoPillarPageComponent } from './pages/verticais/varejo/varejo-pillar-page/varejo-pillar-page.component';
import { SlidesComponent } from './pages/home/components/slides/slides.component';
import { IvyCarouselModule } from 'angular-responsive-carousel';
import { CommonModule } from '@angular/common';
import { InfiniteScrollComponent } from './pages/home/components/infinite-scroll/infinite-scroll.component';
import { SelosComponent } from './pages/solucoes/cloud-services/components/selos/selos.component';
import { DataAnalyticsComponent } from './pages/solucoes/cloud-services/pages/data-analytics/data-analytics.component';
import { WebinarsDetailComponent } from './pages/webinars-detail/webinars-detail.component';
import { RouterModule } from '@angular/router';

@NgModule({
  declarations: [
    AppComponent,
    NavComponent,
    FooterComponent,
    HomeComponent,
    ProgramaInovacaoComponent,
    JornadaProdutividadeComponent,
    JornadaProdEtapasComponent,
    CampanhaLgpdComponent,
    CampanhaLgpdNComponent,
    JornadaModernizacaoComponent,
    JornadaInteligenteComponent,
    JornadaModerEtapasComponent,
    JornadaIntelEtapasComponent,
    CampanhaModernizacaoComponent,
    ClientesComponent,
    VerticaisComponent,
    NotFoundComponent,
    TrabalheConoscoComponent,
    IndustriaComponent,
    LogisticaComponent,
    SaudeComponent,
    TecnologiaComponent,
    VarejoComponent,
    AjudaComponent,
    SobreComponent,
    CasesComponent,
    ServicosComponent,
    SolucoesComponent,
    ModernizacaoInfraComponent,
    JornadaSolucoesComponent,
    ProdutividadeSegurancaComponent,
    InteligenciaDadosComponent,
    SapGooglecloudComponent,
    TrabalhoDaasComponent,
    MigracaoNuvemComponent,
    MigrationFactoryComponent,
    DevopsCloudComponent,
    ContatoComponent,
    GoogleWorkspaceComponent,
    CloudMigratorComponent,
    GestorAssinaturaComponent,
    QiAcademyComponent,
    ServicosProdutividadeComponent,
    ServicosModernizacaoComponent,
    ServicosInteligenciaComponent,
    SsoComponent,
    BancoDadosGooglecloudComponent,
    GoogleDataStudioComponent,
    EbooksComponent,
    WebinarsComponent,
    WebinarsCloudComponent,
    CaseEspecificoComponent,
    WebinarsWorkspaceComponent,
    WebinarsGsuiteComponent,
    SeloComponent,
    SaudePillarPageComponent,
    CertificacoesComponent,
    TecnologiaPillarPageComponent,
    VarejoPillarPageComponent,
    SlidesComponent,
    InfiniteScrollComponent,
    SelosComponent,
    DataAnalyticsComponent,
    WebinarsDetailComponent
  ],
  imports: [
    CommonModule,
    BrowserModule,
    AppRoutingModule,
    SlickCarouselModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    BrowserAnimationsModule,
    IvyCarouselModule,
    RouterModule,
  ],
  providers: [ HelperService ],
  bootstrap: [AppComponent]
})
export class AppModule { }
