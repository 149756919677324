import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { BancoDadosGooglecloudComponent } from './pages/banco-dados-googlecloud/banco-dados-googlecloud.component';
import { CampanhaLgpdNComponent } from './pages/campanha-lgpd-n/campanha-lgpd-n.component';
import { CampanhaLgpdComponent } from './pages/campanha-lgpd/campanha-lgpd.component';
import { CampanhaModernizacaoComponent } from './pages/campanha-modernizacao/campanha-modernizacao.component';
import { CaseEspecificoComponent } from './pages/case-especifico/case-especifico.component';
import { CasesComponent } from './pages/cases/cases.component';
import { CloudMigratorComponent } from './pages/cloud-migrator/cloud-migrator.component';
import { ContatoComponent } from './pages/contato/contato.component';
import { EbooksComponent } from './pages/ebooks/ebooks.component';
import { GestorAssinaturaComponent } from './pages/gestor-assinatura/gestor-assinatura.component';
import { GoogleDataStudioComponent } from './pages/google-data-studio/google-data-studio.component';
import { GoogleWorkspaceComponent } from './pages/google-workspace/google-workspace.component';
import { HomeComponent } from './pages/home/home.component';
import { JornadaIntelEtapasComponent } from './pages/jornada-intel-etapas/jornada-intel-etapas.component';
import { JornadaInteligenteComponent } from './pages/jornada-inteligente/jornada-inteligente.component';
import { JornadaModerEtapasComponent } from './pages/jornada-moder-etapas/jornada-moder-etapas.component';
import { JornadaModernizacaoComponent } from './pages/jornada-modernizacao/jornada-modernizacao.component';
import { JornadaProdEtapasComponent } from './pages/jornada-prod-etapas/jornada-prod-etapas.component';
import { JornadaProdutividadeComponent } from './pages/jornada-produtividade/jornada-produtividade.component';
import { MigracaoNuvemComponent } from './pages/migracao-nuvem/migracao-nuvem.component';
import { NotFoundComponent } from './pages/not-found/not-found.component';
import { ProgramaInovacaoComponent } from './pages/programa-inovacao/programa-inovacao.component';
import { QiAcademyComponent } from './pages/qi-academy/qi-academy.component';
import { SapGooglecloudComponent } from './pages/sap-googlecloud/sap-googlecloud.component';
import { ServicosInteligenciaComponent } from './pages/servicos-inteligencia/servicos-inteligencia.component';
import { ServicosModernizacaoComponent } from './pages/servicos-modernizacao/servicos-modernizacao.component';
import { ServicosProdutividadeComponent } from './pages/servicos-produtividade/servicos-produtividade.component';
import { ServicosComponent } from './pages/servicos/servicos.component';
import { SobreComponent } from './pages/sobre/sobre.component';
import { InteligenciaDadosComponent } from './pages/solucoes/inteligencia-dados/inteligencia-dados.component';
import { ModernizacaoInfraComponent } from './pages/solucoes/cloud-services/cloud-services.component';
import { ProdutividadeSegurancaComponent } from './pages/solucoes/produtividade-colaboracao/produtividade-colaboracao.component';
import { SolucoesComponent } from './pages/solucoes/solucoes.component';
import { SsoComponent } from './pages/sso/sso.component';
import { TrabalheConoscoComponent } from './pages/trabalhe-conosco/trabalhe-conosco.component';
import { TrabalhoDaasComponent } from './pages/trabalho-daas/trabalho-daas.component';
import { IndustriaComponent } from './pages/verticais/industria/industria.component';
import { LogisticaComponent } from './pages/verticais/logistica/logistica.component';
import { SaudeComponent } from './pages/verticais/saude/saude.component';
import { TecnologiaComponent } from './pages/verticais/tecnologia/tecnologia.component';
import { VarejoComponent } from './pages/verticais/varejo/varejo.component';
import { VerticaisComponent } from './pages/verticais/verticais.component';
import { WebinarsCloudComponent } from './pages/webinars-cloud/webinars-cloud.component';
import { WebinarsGsuiteComponent } from './pages/webinars-gsuite/webinars-gsuite.component';
import { WebinarsWorkspaceComponent } from './pages/webinars-workspace/webinars-workspace.component';
import { WebinarsComponent } from './pages/webinars/webinars.component';
import { MigrationFactoryComponent } from './pages/migration-factory/migration-factory.component';
import { DevopsCloudComponent } from './pages/devops-cloud/devops-cloud.component';
import { CertificacoesComponent } from './pages/certificacoes/certificacoes.component';
import { DataAnalyticsComponent } from './pages/solucoes/cloud-services/pages/data-analytics/data-analytics.component';
import { WebinarsDetailComponent } from './pages/webinars-detail/webinars-detail.component';


const routes: Routes = [
  { path: '', pathMatch: 'full', redirectTo: 'home'},
  { path: 'home', component: HomeComponent},
  { path: 'programa-inovacao', component: ProgramaInovacaoComponent },
  { path: 'jornada-de-produtividade-e-seguranca', component: JornadaProdutividadeComponent },
  { path: 'etapas-jornada-de-produtividade-e-seguranca', component: JornadaProdEtapasComponent },
  { path: 'jornada-de-modernizacao-de-infra', component: JornadaModernizacaoComponent },
  { path: 'etapas-jornada-de-modernizacao-de-infra', component: JornadaModerEtapasComponent },
  { path: 'jornada-de-inteligencia-de-dados', component: JornadaInteligenteComponent },
  { path: 'etapas-jornada-de-inteligencia-de-dados', component: JornadaIntelEtapasComponent },
  { path: 'campanha-lgpd', component: CampanhaLgpdComponent },
  { path: 'campanha-lgpd-n', component: CampanhaLgpdNComponent },
  { path: 'campanha-modernizacao-google-cloud', component: CampanhaModernizacaoComponent },
  { path: 'verticais', component: VerticaisComponent,
    children: [
      { path: '', pathMatch: 'full', redirectTo: 'industria'},
      { path: 'industria', component: IndustriaComponent},
      { path: 'logistica', component: LogisticaComponent},
      { path: 'saude', component: SaudeComponent},
      { path: 'tecnologia', component: TecnologiaComponent},
      { path: 'varejo', component: VarejoComponent}
    ]
  },
  { path: 'trabalhe-conosco', component: TrabalheConoscoComponent},
  { path: 'certificacoes', component: CertificacoesComponent},
  { path: 'sobre', component: SobreComponent},
  { path: 'cases', component: CasesComponent},
  { path: 'case/:id', component: CaseEspecificoComponent},
  { path: 'servicos', component: ServicosComponent,
    children: [
      {path: '', pathMatch: 'full', redirectTo: 'servico-produtividade'},
      {path: 'servico-produtividade', component: ServicosProdutividadeComponent},
      {path: 'servico-modernizacao', component: ServicosModernizacaoComponent},
      {path: 'servico-inteligencia', component: ServicosInteligenciaComponent},
    ]
  },
  {path: 'solucoes', component: SolucoesComponent,
    children: [
      {path: '', pathMatch: 'full', redirectTo: 'cloud-services'},
      {path: 'cloud-services', component: ModernizacaoInfraComponent},
      {path: 'produtividade-colaboracao', component: ProdutividadeSegurancaComponent},
      {path: 'inteligencia-dados', component: InteligenciaDadosComponent},
    ]
  },
  {path: 'solucoes/produtividade-colaboracao/google-workspace', component: GoogleWorkspaceComponent},
  {path: 'solucoes/produtividade-colaboracao/cloud-migrator', component: CloudMigratorComponent},
  {path: 'solucoes/produtividade-colaboracao/gestor-assinatura', component: GestorAssinaturaComponent},
  {path: 'solucoes/produtividade-colaboracao/qi-academy', component: QiAcademyComponent},
  {path: 'solucoes/produtividade-colaboracao/sso', component: SsoComponent},
  {path: 'solucoes/cloud-services/:id', component: DataAnalyticsComponent},
  {path: 'solucoes/cloud-services/trabalho-daas', component: TrabalhoDaasComponent},
  {path: 'solucoes/cloud-services/migracao-nuvem', component: MigracaoNuvemComponent},
  {path: 'solucoes/cloud-services/migration-factory', component: MigrationFactoryComponent},
  {path: 'solucoes/cloud-services/devops-cloud', component: DevopsCloudComponent},
  {path: 'solucoes/inteligencia-dados/banco-dados-googlecloud', component: BancoDadosGooglecloudComponent},
  {path: 'solucoes/inteligencia-dados/google-data-studio', component: GoogleDataStudioComponent},
  {path: 'contato', component: ContatoComponent},
  {path: 'ebooks', component: EbooksComponent},
  {path: 'webinars', component: WebinarsComponent},
  {path: 'webinars/:id',component: WebinarsDetailComponent},
  {path: 'webinars-cloud', component: WebinarsCloudComponent},
  {path: 'webinars-workspace', component: WebinarsWorkspaceComponent},
  {path: 'webinars-g-suite', component: WebinarsGsuiteComponent},
  {path: '**',pathMatch: 'full', redirectTo: '/404'},
  {path: '404', component: NotFoundComponent},
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
